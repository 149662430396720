import React from "react"
import { graphql, Link } from "gatsby"

import Layout, { athenaTheme } from "../../components/layout"
import OffsetGrid from "../../components/athena/offsetGrid"
import Image from "../../components/image"
import PreFooter from "../../components/athena/preFooter"
import Arrow from "../../images/arrow-black.svg"
import HeaderImage from "../../components/headerImage"
import styles from "./issue.module.scss"

    let theme = {
      ...athenaTheme,
      background: styles.background
    }


export default ({ data }) => {
  const issue = data.craft.page
  const seo = issue.seomatic
  const coverImages = issue.coverImages[0]
  const issueNum = 1 + data.craft.issues.findIndex(item => item.id === issue.id)
  // Get only the articles that belong to this issue
  const articles = data.craft.articles.filter(
    article => article.issue[0].id === issue.id
  )
  const aspectRatios = ["1x1", "3x4", "4x3"]
  let arIndex = 0

  

  

  // Add all articles to an array that we'll pass to <OffsetGrid />
  const gridItems =
    articles.length > 0
      ? articles.map(article => {
          // Loop through set aspect ratios
          const numOfAspectRatios = aspectRatios.length - 1
          let ar = aspectRatios[arIndex]
          arIndex = arIndex === numOfAspectRatios ? 0 : ++arIndex
          const status = article.status;
          const artID = issue.id;
          return (
            <article key={`home-article-${article.id}`}>
              {status === 'live' &&(
              <Link 
                className="img-tile"
                to={`/${article.uri}`}>
                <div className="mb-4">
                  {/*
                      This allows us to use the padding-bottom aspect ratio
                      technique so content doesn't jump or get measured incorrectly
                    */}
                  {artID === '24834' || '25509' ?
                    <div className={styles.issueFour}> 
                      <div className={`img-tile-image img-tile-ar img-tile-ar-${ar}`} >
                        <Image
                          height={article.coverImages[0][`ratio_${ar}`][0].height}
                          width={article.coverImages[0][`ratio_${ar}`][0].width}
                          sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                          url={article.coverImages[0][`ratio_${ar}`][0].url}
                          altText={article.coverImages[0][`ratio_${ar}`][0].altText}
                        />
                      </div>
                    </div> 
                  :
                    <div className={`img-tile-image img-tile-ar img-tile-ar-${ar}`} >
                      <Image
                        height={article.coverImages[0][`ratio_${ar}`][0].height}
                        width={article.coverImages[0][`ratio_${ar}`][0].width}
                        sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                        url={article.coverImages[0][`ratio_${ar}`][0].url}
                        altText={article.coverImages[0][`ratio_${ar}`][0].altText}
                      />
                    </div>
                  }
                </div>
                
                  <div className={styles.articleText}>
                    {artID === '24834' || '25509' ? null : 
                    <p className="a-h-6">{article.articleSubTitle}</p>
                    }
                    <h3 className="a-h-3 mb-2 italic">
                      <span className="img-tile-title-underline">
                        {article.title}
                      </span>
                    </h3>
                  
                  <div
                  className="user-content-athena body-after-tablet"
                  dangerouslySetInnerHTML={{
                    __html: article.snippet,
                  }}
                  />
                    
                  <div className={styles.articleArrow}>
                    <Arrow />
                  </div>
                </div>
              </Link>
            )}
            {(artID === '24834' && status === 'disabled') && (
              <div className="">
                <div className={styles.issueFour}>
                  <div className={`img-tile-image img-tile-ar img-tile-ar-${ar}`} >
                    <Image
                      height={article.coverImages[0][`ratio_${ar}`][0].height}
                      width={article.coverImages[0][`ratio_${ar}`][0].width}
                      sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                      url={article.coverImages[0][`ratio_${ar}`][0].url}
                      altText={article.coverImages[0][`ratio_${ar}`][0].altText}
                    />
                  </div>
                </div>
                <div className={styles.articleText}>
                  <h3 className="a-h-3 mb-2 italic">
                    {article.title}
                  </h3>
                  <div className="user-content-athena body-after-tablet">
                    <p>Coming Soon!</p>
                  </div>
                </div>
              </div>
            )}
            {(artID === '25509' && status === 'disabled') && (
              <div className="">
                <div className={styles.issueFour}>
                  <div className={`img-tile-image img-tile-ar img-tile-ar-${ar}`} >
                    <Image
                      height={article.coverImages[0][`ratio_${ar}`][0].height}
                      width={article.coverImages[0][`ratio_${ar}`][0].width}
                      sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                      url={article.coverImages[0][`ratio_${ar}`][0].url}
                      altText={article.coverImages[0][`ratio_${ar}`][0].altText}
                    />
                  </div>
                </div>
                <div className={styles.articleText}>
                  <h3 className="a-h-3 mb-2 italic">
                    {article.title}
                  </h3>
                  <div className="user-content-athena body-after-tablet">
                    <p>Coming Soon!</p>
                  </div>
                </div>
              </div>
            )}
          </article>
        )
        })
      : null
    return (
    <Layout 
    seo={seo} 
    mode="athena"
    theme={issueNum === 4 ? theme : null}
    >
    
      <div className="page-content-module">
        <div className="wrapper">
          <div className={styles.title}>
            <div className="columns is-desktop is-centered text-center">
              <div className="column is-8-desktop">
                <div className="mb-2">
                  <p className="a-h-5">
                    Issue {issueNum.toString().padStart(2, "0")}
                  </p>
                </div>
                <h1 className="a-h-display">{issue.title}</h1>
              </div>
            </div>
          </div>
          {/* .issueTitle */}
          <div className="columns is-desktop is-centered no-vertical-gap">
            <div className="column is-10-desktop">
              <HeaderImage data={coverImages} />
            </div>
          </div>
          <div className={styles.question}>
            <div className="columns is-tablet is-centered">
              <div className="column is-10-tablet is-8-desktop is-6-fullhd">
                <p
                  dangerouslySetInnerHTML={{
                    __html: issue.issueSubtitle,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.articles}>
            <OffsetGrid items={gridItems} />
          </div>
        </div>
      </div>
      <PreFooter/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    craft {
      issues: categories(group: "athenaIssues") {
        id
      }
      page: category(slug: [$slug], group: "athenaIssues") {
        title
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaIssues_Category {
          issueSubtitle
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
        }
      }
      articles: entries(section: "athenaArticles", status: ["live", "disabled"]) {
        id
        title
        uri
        status
        ... on Craft_athenaArticles_athenaArticleTemplated_Entry {
          snippet
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
              ratio_4x3 {
                url
              }
              ratio_3x4 {
                url
              }
              ratio_16x9 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          snippet
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
              ratio_4x3 {
                url
              }
              ratio_3x4 {
                url
              }
              ratio_16x9 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
      }
    }
  }
`
